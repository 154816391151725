import jQuery from "jquery";
(function ($) {
    var $acceptTemsCheckbox = $('.toggleAcceptTerms');
    var $continueButton = $('.continueButton');


    $acceptTemsCheckbox.click(function () {
        // Set continue button to disabled if checkbox is not checked
        if ($(this).is(':checked')) {
            $continueButton.prop('disabled', false);
        }
        else {
            $continueButton.prop('disabled', true);
        }
    });

})(jQuery);