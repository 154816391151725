$(document).ready(function () {
  /* B2B */
  if ($(window).width() > 1199) {
    $('.b2b-header .b2b-menu .nav-item.dropdown').hover(function () {
      $('.b2b-header').toggleClass('open-menu')
    })
  }

    if ($(window).width() < 1200) {
        $('.b2b-header .b2b-menu .nav-item.dropdown > a').on(
            'click',
            function (event) {
                event.preventDefault()
            }
        )

    $('.b2b-header li.has-children > a').on('click', function (event) {
      event.preventDefault()
      $(event.target)
        .closest('.dropdown-item')
        .next('.dropdown-menu')
        .addClass('open-sub-menu')
      $('li.has-children').addClass('opened-menu')
    })

    $('.b2b-header .go-back').on('click', function (e) {
      $(e.target)
        .closest('.go-back')
        .closest('.dropdown-menu')
        .removeClass('open-sub-menu')
      if ($('li.has-children').hasClass('opened-menu')) {
        $('li.has-children').removeClass('opened-menu')
      }
    })

    $('.b2b-header .dropdown > a').on('click', function () {
      $(this)
        .closest('.dropdown')
        .children('.dropdown-menu')
        .addClass('open-sub-menu')
    })
  }
  $(window).resize(function () {
    if ($(window).width() > 1199) {
      $('.b2b-header .b2b-menu .nav-item.dropdown').hover(function () {
        $('.b2b-header').toggleClass('open-menu')
      })
    }

    if ($(window).width() < 1200) {
      $('.b2b-header .b2b-menu .nav-item.dropdown > a').on(
        'click',
        function (event) {
          event.preventDefault()
        }
      )

      $('.b2b-header li.has-children > a').on('click', function (event) {
        event.preventDefault()
        $(event.target)
          .closest('.dropdown-item')
          .next('.dropdown-menu')
          .addClass('open-sub-menu')
        $('li.has-children').addClass('opened-menu')
      })

      $('.b2b-header .go-back').on('click', function (e) {
        $(e.target)
          .closest('.go-back')
          .closest('.dropdown-menu')
          .removeClass('open-sub-menu')
        if ($('li.has-children').hasClass('opened-menu')) {
          $('li.has-children').removeClass('opened-menu')
        }
      })

      $('.b2b-header .dropdown > a').on('click', function () {
        $(this)
          .closest('.dropdown')
          .children('.dropdown-menu')
          .addClass('open-sub-menu')
      })
    }
  })

  $('.b2b-header .dropdown > a').on('click', function () {
    $(this)
      .closest('.dropdown')
      .children('.dropdown-menu')
      .addClass('open-sub-menu')
  })

  /* invest */
  $('.navbar-toggler span').on('click', function () {
    if ($('.open-search').hasClass('open')) {
      $('.open-search').removeClass('open')
      $('.sticky-top').removeClass('open-search')
      $('.open-search span.icon-close').fadeOut(150, function () {
        $('.open-search span.icon-search').fadeIn()
      })
    }
    if ($('.navbar-toggler .icon-default').hasClass('open')) {
      $('.navbar-toggler .icon-default').removeClass('open')
      $('.sticky-top').removeClass('open')
      $('.navbar-toggler .icon-active').fadeOut(150, function () {
        $('.navbar-toggler .icon-default').fadeIn()
      })
    } else {
      $('.navbar-toggler .icon-default').addClass('open')
      $('.sticky-top').addClass('open')
      $('.navbar-toggler .icon-default').fadeOut(150, function () {
        $('.navbar-toggler .icon-active').fadeIn()
      })
    }
  })

  $('.open-search span').on('click', function () {
    if ($('.navbar-toggler .icon-default').hasClass('open')) {
      $('.navbar-toggler .icon-default').removeClass('open')
      $('.sticky-top').removeClass('open')
      $('.navbar-toggler .icon-active').fadeOut(150, function () {
        $('.navbar-toggler .icon-default').fadeIn()
      })
    }

    if ($('.open-search').hasClass('open')) {
      $('.open-search').removeClass('open')
      $('.sticky-top').removeClass('open-search')
      $('.open-search span.icon-close').fadeOut(150, function () {
        $('.open-search span.icon-search').fadeIn()
      })
    } else {
      $('.open-search').addClass('open')
      $('.sticky-top').addClass('open-search')
      $('.open-search span.icon-search').fadeOut(150, function () {
        $('.open-search span.icon-close').fadeIn()
      })
      $('#site-search').focus()
    }
  })

  $('.invest-header .dropdown-arrow').on('click', function () {
    $(this).toggleClass('open').next().slideToggle()
  })

  $('#accordian').click(function (e) {
    var menu_item = $(e.target)

    if (is_active(menu_item)) {
      console.log('active menu item clicked')
      close_menu_item(menu_item)
    } else {
      console.log('inactive menu item clicked')
      open_menu_item(menu_item)
    }
  })

  $('.CurrencySelectorRadiobutton').on('change', function (e) {
    var $this = $(e.target)
    var $form = $this.closest('form')
    if ($this.prop('checked')) {
      $form.submit()
    }
  })

  $('.currencyInputLabel').on('click', function (e) {
    var $this = $(e.target).closest('label')
    var $form = $this.closest('form')
    var changeCurrencyWarning = $('.confirm-text').val()

    if ($('header .bottom-right > a').hasClass('has-items')) {
      if (confirm(changeCurrencyWarning) == false) {
        return false
      }
    }
  })

  function close_menu_item(menu_item) {
    console.log('1x')

    menu_item.find('ul').each(function () {
      $(this).slideUp().find('li').removeClass('active-menu-item')
    })

    menu_item.removeClass('active-menu-item')
  }

  function open_menu_item(menu_item) {
    menu_item.children('ul').slideDown(500)
    menu_item.addClass('active-menu-item')
  }

  function close_menu_item(menu_item) {
    console.log('1x')

    menu_item.find('ul').each(function (index, elem) {
      $(elem).slideUp()
      return false
    })

    menu_item.removeClass('active-menu-item')
  }

  function is_active(menu_item) {
    return menu_item.hasClass('active-menu-item')
  }
})
