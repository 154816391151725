import { updateCartTotals } from './_uCommerce.demostore'
var _itemAddedAlert = null;

$(function () {
	if ($('#product-details, #review-form').length === 0) {
		return;
	}

	relateVariations($('#product-sku'), $('#variation-CollarSize'), $('#variation-Colour'), $('#add-to-basket'));
	enableAddToCartWhenSelected($('#add-to-basket'), $('.js-variant'));
	wireupAddToCartButton($('#add-to-basket'), $('#catalog-id'), $('#product-sku'), $('.js-variant'), $('#quantity-to-add'), $('#product-type'), $('#product-gram-price'), $('#InputLength'), $('#InputWidth'), $('#InputWeight'), $('#InputCharacterString'), $('#InputProfileDropdown'), $('#InputInnerDiameter'), $('#InputInnerCircumference'), $('#InputMeasurementA'), $('#InputMeasurementB'), $('#InputMeasurementC'), $('#InputYearDropdown'));
	wireupRatings($('.rating'));
	submitReview();
});

function relateVariations(sku, size, colour) {
	updateVariationOptions(sku, size, colour, true);
	size.change(function () {
		// Reset the colour options
		$('option:first', colour).attr('selected', true);
		updateVariationOptions(sku, size, colour, true);
	});
	colour.change(function () {
		updateVariationOptions(sku, size, colour, true);
	});
};
function enableAddToCartWhenSelected(addToCartButton, variantInputs) {
	if (variantInputs.length == 0)
		return;

	addToCartButton.removeClass('btn-success').addClass('disabled');

	variantInputs.change(function () {
		updateAddToCartButton(addToCartButton, variantInputs);
	});
};

function wireupRatings(radios) {
	var $ratings = $('.js-rating label');

	$ratings.on('click', function(){
		var currentIndex = $(this).index();

		$ratings.each(function(){
			if ($(this).index() <= currentIndex) {
				$(this).addClass('active');
			} else {
				$(this).removeClass('active');
			}
		});
	});
};

function submitReview() {
	if (sessionStorage.getItem("ACReviewSubmitted")) {
		$('html, body').animate({ scrollTop: $('#review').offset().top }, 'medium');
		sessionStorage.clear();
	}

	$('#review').on('submit', function(){
		sessionStorage.setItem("ACReviewSubmitted", true);
		return true;
	});
}

function updateAddToCartButton(addToCartButton, variantInputs) {
	if (variantInputs.length == 0)
		return;

	var empty = variantInputs.filter(function () { return this.value === ""; });

	// If the user has made a valid selection enable the add to cart button
	if (empty.length == 0) {
		addToCartButton.removeClass('disabled').addClass('btn-success').removeAttr('disabled');
	} else {
		addToCartButton.removeClass('btn-success').addClass('disabled').attr('disabled', 'disabled');
	}
};


function wireupAddToCartButton(addToCartButton, catalogIdInput, skuInput, variantInputs, quantityInput, productType, productGramPrice, inputLength, inputWidth, inputWeight, characterString, inputProfileDropdown, inputInnerDiameter, inputInnerCircumference, inputMeasurementA, inputMeasurementB, inputMeasurementC, inputYearDropdown) {
	addToCartButton.click(function (e) {
		e.preventDefault(); 

		let productTypeValue = $("#product-type").val();
		let allValid = IsValid(productTypeValue);

		if (allValid) {

			var sku = skuInput.val();
			var myarray = {};
			variantInputs.each(function (i, v) {
				var t = $(v);
				myarray[t.attr("id").replace("variation-", "")] = t.val();
			});
			var qty = quantityInput.val();
			var pgp = (productGramPrice.val() + "").replace(',', '.');
			var length = inputLength ? (inputLength.val() + "").replace(',', '.') : null;
			var width = inputLength ? (inputWidth.val() + "").replace(',', '.') : null;
			var weight = inputWeight ? (inputWeight.val() + "").replace(',', '.') : null;
			var pcs = characterString ? (characterString.val() + "") : null;
			var iyd = inputYearDropdown ? (inputYearDropdown.val() + "") : null;
			var pr = inputProfileDropdown ? (inputProfileDropdown.val()) : null;
			var iid = inputInnerDiameter ? (inputInnerDiameter.val() + "") : null;
			var iic = inputInnerCircumference ? (inputInnerCircumference.val() + "") : null;
			var ma = inputMeasurementA ? (inputMeasurementA.val() + "") : null;
			var mb = inputMeasurementB ? (inputMeasurementB.val() + "") : null;
			var mc = inputMeasurementB ? (inputMeasurementC.val() + "") : null;


			$.uCommerce.getVariantSkuFromSelection(
				{
					productSku: sku,
					variantProperties: myarray
				},
				function (data) {
					var variant = data.Variant;
					if (variant != null) {


						$.uCommerce.addToBasket(
							{
								catalogId: catalogIdInput.val(),
								sku: variant.Sku,
								variantSku: variant.VariantSku,
								variantPriceGroupGuid: variant.PriceGroupGuid,
								quantity: qty,
								basePricePerGram: pgp,
								productLength: length,
								productWidth: width,
								productWeight: weight,
								productCharacterString: pcs,
								productProfile: pr,
								productInnerDiameter: iid,
								productInnerCircumference: iic,
								productMeasurementA: ma,
								productMeasurementB: mb,
								productMeasurementC: mc,
								productYear: iyd
							},
							function () {
								updateCartTotals(addToCartButton);

								var parent = addToCartButton.parent();
								var alert = parent.find(".item-added");
								if (alert.length == 0) {
									// Add an alert box so the customer knows they've added an item to the cart
									alert = $('<div />', {
										"class": "alert alert-success item-added",
										html: '<button type="button" class="close" data-dismiss="alert">×</button><p>' + itemAddedToBasketTranslated + '<br/> <a href="' + cmsUrlPrefix + '/basket">' + clickToViewBasketTranslated + '.</a></p>'
									}).hide();
									parent.append(alert);
									alert.slideDown();
								} else {
									//alert.effect("highlight", { color: '#FCF8E3' }, 500);
								}

								// Incase there's already a timeout in place, clear it
								clearTimeout(_itemAddedAlert);

								// Remove the alert after 5 seconds
								_itemAddedAlert = setTimeout(function () {
									alert.slideUp(500, function () {
										alert.remove();
									});
								}, 5000);
							}
						);
					}
				});
		}
		return false;
	});
};
function updateVariationOptions(sku, size, colour, userAction, success, failure) {
	var selectedSize = size.val();
	var colourOptions = $('option', colour).not('option[value=""]');

	// Start by disabling all the options
	colourOptions.attr('disabled', 'disabled');

	$.uCommerce.getProductVariations(
            { productSku: sku.val() },
            function (data) {
            	$.each(data.Variations, function (i, v) {
            		var variationSize = getObjectsByKey(v.Properties, 'Name', 'CollarSize')[0];
            		var variationColour = getObjectsByKey(v.Properties, 'Name', 'Colour')[0];

            		// The user hasn't yet selected a size so offer them all colours (so they can see the list)
            		if (selectedSize == '') {
            			colourOptions.removeAttr('disabled');
            			return true;
            		}

            		// If the size matches the selected size, this colour is available so enable it in the drop down list
            		if (variationSize && variationSize.Value == selectedSize) {
            			$('option[value="' + variationColour.Value + '"]', colour).removeAttr('disabled');
            		}
            	});

            	// If there is only a single item in the list then select it
            	var availableOptions = $('option', colour).not('option[value=""]').not(':disabled');

            	if (selectedSize != '' && availableOptions.length == 1 && userAction) {
            		colour.val(availableOptions.val());
            		//Fire these events manually to prevent a loop
            		updateVariationOptions(sku, size, colour, false);
            		updateAddToCartButton($('#add-to-basket'), $('.js-variant'));
            	}

            	// Now call any functions that need to run after the updates
            	if (success)
            		success();
            },
            failure
        );
}
function getObjectsByKey(obj, key, val) {
	var objects = [];
	for (var i in obj) {
		if (!obj.hasOwnProperty(i)) continue;
		if (typeof obj[i] == 'object') {
			objects = objects.concat(getObjectsByKey(obj[i], key, val));
		} else if (i == key && obj[key] == val) {
			objects.push(obj);
		}
	}
	return objects;
}

function IsValid(type) {
	let isValid = true;

	if (type === 'RingMaterial') {
		let diameterInput = document.getElementById("InputInnerDiameter");
		let measurementAInput = document.getElementById("InputMeasurementA");

		if (typeof diameterInput !== 'undefined' && diameterInput && diameterInput.value.trim() === "" || diameterInput.value.trim() === "0") {
			let diameterErrorMessage = document.getElementById("innerDiameterValidationError");
			diameterErrorMessage.style.display = "block";

			isValid = false;
		}
		else {
			let diameterErrorMessage = document.getElementById("innerDiameterValidationError");
			if (diameterErrorMessage) {
				diameterErrorMessage.style.display = "none";
			}
		}
		if (typeof measurementAInput !== 'undefined' && measurementAInput && measurementAInput.value.trim() === "") {
			let measurementAErrorMessage = document.getElementById("measurementAValidationError");
			measurementAErrorMessage.style.display = "block";

			isValid = false;
		}
		else {
			let measurementAErrorMessage = document.getElementById("measurementAValidationError");
			if (measurementAErrorMessage) {
				measurementAErrorMessage.style.display = "none";
			}
		}
		let measurementBDiv = document.getElementById("measurement-b-div");
		let measurementCDiv = document.getElementById("measurement-c-div");

		if (measurementBDiv && measurementBDiv.style.display !== "none") {
			let measurementBInput = document.getElementById('InputMeasurementB');
			if (typeof measurementBInput !== 'undefined' && measurementBInput && measurementBInput.value.trim() === "") {
				let measurementBErrorMessage = document.getElementById("measurementBValidationError");
				measurementBErrorMessage.style.display = "block";
				isValid = false;
			}
			else {
				let measurementBErrorMessage = document.getElementById("measurementBValidationError");
				if (measurementBErrorMessage) {
					measurementBErrorMessage.style.display = "none";
				}
			}
		}
		if (measurementCDiv && measurementCDiv.style.display !== "none") {
			let measurementCInput = document.getElementById('InputMeasurementC');
			if (typeof measurementCInput !== 'undefined' && measurementCInput && measurementCInput.value.trim() === "") {
				let measurementCErrorMessage = document.getElementById("measurementCValidationError");
				measurementCErrorMessage.style.display = "block";
				isValid = false;
			}
			else {
				let measurementCErrorMessage = document.getElementById("measurementCValidationError");
				if (measurementCErrorMessage) {
					measurementCErrorMessage.style.display = "none";
				}
			}
		}
	}
	if (type === 'Plate') {
		let lengthInput = document.getElementById("InputLength");
		let widthInput = document.getElementById("InputWidth");

		if (typeof lengthInput !== 'undefined' && lengthInput && lengthInput.value.trim() === "" || lengthInput.value.trim() === "0") {
			let lengthErrorMessage = document.getElementById("lengthValidationError");
			lengthErrorMessage.style.display = "block";

			isValid = false;
		}
		else {
			let lengthErrorMessage = document.getElementById("lengthValidationError");
			if (lengthErrorMessage) {
				lengthErrorMessage.style.display = "none";
            }
        }
		if (typeof widthInput !== 'undefined' && widthInput && widthInput.value.trim() === "" || widthInput.value.trim() === "0") {
			let widthErrorMessage = document.getElementById("widthValidationError");
			widthErrorMessage.style.display = "block";

			isValid = false;
		}
		else {
			let widthhErrorMessage = document.getElementById("widthValidationError");
			if (widthhErrorMessage) {
				widthhErrorMessage.style.display = "none";
			}
		}
	}
	if (type === 'Plane' || type === 'Thread' || type === 'HalfThread' || type === 'RoundPipe' || type === 'SquareThread' || type === 'SquarePipe') {
		let lengthInput = document.getElementById("InputLength");

		if (typeof lengthInput !== 'undefined' && lengthInput && lengthInput.value.trim() === "" || lengthInput.value.trim() === "0") {
			let lengthErrorMessage = document.getElementById("lengthValidationError");
			lengthErrorMessage.style.display = "block";

			isValid = false;
		}
		else {
			let lengthErrorMessage = document.getElementById("lengthValidationError");
			if (lengthErrorMessage) {
				lengthErrorMessage.style.display = "none";
			}
		}
	}
	if (type === 'Granulates') {
		let weightInput = document.getElementById("InputWeight");

		if (typeof weightInput !== 'undefined' && weightInput && weightInput.value.trim() === "" || weightInput.value.trim() === "0") {
			let weighthErrorMessage = document.getElementById("weightValidationError");
			weighthErrorMessage.style.display = "block";

			isValid = false;
		}
		else {
			let weighthErrorMessage = document.getElementById("weighthValidationError");
			if (weighthErrorMessage) {
				weighthErrorMessage.style.display = "none";
			}
		}
	}

	let amountInput = document.getElementById("quantity-to-add");
	if (typeof amountInput !== 'undefined' && amountInput && amountInput.value.trim() === "" || amountInput.value.trim() === "0") {
		let amounthErrorMessage = document.getElementById("amountValidationError");
		amounthErrorMessage.style.display = "block";

		isValid = false;
	}
	else {
		let amounthErrorMessage = document.getElementById("amountValidationError");
		if (amounthErrorMessage) {
			amounthErrorMessage.style.display = "none";
		}
	}

	return isValid;
}