import { updateCartTotals } from './_uCommerce.demostore'

$(function () {
    wireUpCart($('#basket'));
});

function wireUpCart(basket) {
    wireupQuantityChange(basket);
    wireUpClickHandlers(basket);
};

function wireupQuantityChange(basket) {
    $('tr.order-line', basket).each(function (i, row) {
        addUpdateButton(basket, row);
    });
};

function addUpdateButton(basket, row) {
    var qty = $('.qty', row);
    
    qty.data('original', qty.val());

    watchForQuantityChange(qty, basket);
};

function watchForQuantityChange(qty, basket) {
    qty.change(function () {
        var t = $(this);

        $(/*'.update-basket',*/ t.parent()).fadeIn();

        enableUpdateButton(basket);
    });
};

function wireUpClickHandlers(basket) {
    disableUpdateButton(basket);
    $('.update-basket', basket).click(function (e) {
        console.log('click');
        e.preventDefault();
        var t = $(this);
        var qty = $('.qty', t.parent());
        qty.addClass('pending-change');
        $.uCommerce.updateLineItem({
            orderLineId: qty.data("orderlineid"),
            newQuantity: qty.val()
        },
        function (updatedLine) {
            updateCartTotals();
            t.fadeOut();
            qty.removeClass('pending-change');
            var row = t.parents('tr');
            updateLineTotals(row, updatedLine.UpdatedLine);
            updateQuantity(row, updatedLine.UpdatedLine);
            if ($('.pending-change', basket).length > 0) {
                enableUpdateButton(basket);
            } else {
                disableUpdateButton(basket);
            }
        });
        return false;
    });

    $('.qty').blur(function () {
        console.log('blur');
        var t = $(this);
        var qty = $('.qty', t.parent());

        qty.addClass('pending-change');

        $.uCommerce.updateLineItem({
            orderLineId: qty.data("orderlineid"),
            newQuantity: qty.val()
        }, function (updatedLine) {
            console.log(JSON.stringify(updatedLine));
            updateCartTotals();

            qty.removeClass('pending-change');

            var row = t.parents('tr');

            updateLineTotals(row, updatedLine);
            updateQuantity(row, updatedLine);

            if ($('.pending-change', basket).length > 0) {
                enableUpdateButton(basket);
            } else {
                disableUpdateButton(basket);
            }
        });
    });
};

function updateLineTotals(row, line) {
    $('.line-total', row).text(line.FormattedTotal);
};

function updateQuantity(row, line) {
    $('.qty', row).data('original', line.Quantity);
};

function enableUpdateButton(basket) {
    $(/*'.update-basket',*/ basket).removeClass('disabled').removeAttr('disabled');
};

function disableUpdateButton(basket) {
    $(/*'.update-basket',*/ basket).addClass('disabled').attr('disabled', 'disabled');
};