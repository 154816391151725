$(function () {
    var $body = $('body');
    var throttleInterval = 250;

    if ($('#catalogue').length) {
        var $loadMoreBtn = $('.js-load-more');
        var $numResultsSelect = $('#resultsToShow');
        var $sortOrder = $('#sortOrder');
        var $products = $('#products');
        var perPage = $numResultsSelect.val();
        var sortOrder = $sortOrder.val();
        var size = $('#products').length;
        var page = 1;
        var totalProducts = parseInt($products.data('num-products'));
        var searchParams = new URLSearchParams(window.location.search);

        if (searchParams.get('size') && searchParams.get('size').length) {
            size = parseInt(searchParams.get('size'));

            $('html, body').animate({
                scrollTop: $products.offset().top - 200
            }, 2000);
        }

        if (searchParams.get('pg') && searchParams.get('pg').length) {
            page = parseInt(searchParams.get('pg'));
        }

        if (searchParams.get('pp') && searchParams.get('pp').length) {
            perPage = parseInt(searchParams.get('pp'));
        }

        if (perPage && $numResultsSelect.find('option[value=' + perPage + ']').length) {
            $numResultsSelect.val(perPage);
        }

        if (searchParams.get('sortOrder') && searchParams.get('sortOrder').length) {
            sortOrder = searchParams.get('sortOrder');
            $sortOrder.val(searchParams.get('sortOrder'));
        }

        if (size * page >= totalProducts) {
            $loadMoreBtn.addClass('d-none');
        }

        $numResultsSelect.on('click', function () {
            if ($(this).val() != perPage) {
                searchParams.set('pg', '1');
                searchParams.set('size', $(this).val());
                searchParams.set('pp', $(this).val());
                window.location.search = searchParams.toString();
            }
        });

        $sortOrder.on('change', function () {
            if ($(this).val() !== sortOrder) {
                searchParams.set('pg', '1');
                searchParams.delete('size');
                searchParams.delete('pp');
                searchParams.set('sortOrder', $(this).val());
                window.location.search = searchParams.toString();
            }
        });

        var $paginatorItems = $('.js-page-item');

        $loadMoreBtn.on('click', function () {
            var currentPaginatorIndex = $paginatorItems.filter('.active').index();

            if (currentPaginatorIndex < $paginatorItems.length - 1) {
                var URL = $paginatorItems.eq(currentPaginatorIndex + 1).find('a').attr('href');

                $.get(URL, function (data) {
                    var nextHtml = $(data).find('#products').html();

                    $('html, body').animate({ scrollTop: $products.offset().top + $products.outerHeight() - 60 }, 'medium');
                    $products.append(nextHtml);

                    $('.js-products-count').text($products.find('.js-product').length);
                    $('.js-page-item').removeClass('active');
                    currentPaginatorIndex = currentPaginatorIndex + 1;
                    $('.js-page-item').eq(currentPaginatorIndex).addClass('active');

                    if (currentPaginatorIndex == $('.js-page-item').length - 1) {
                        $loadMoreBtn.addClass('d-none');
                    }
                });
            }
        });
    }

    $('#main-nav').on('hidden.bs.collapse', function () {
        hideCollapse('category-nav');
    });

    $('#main-nav').on('show.bs.collapse', function () {
        hideCollapse('navbar-form');
    });

    $('#navbar-form').on('show.bs.collapse', function () {
        hideCollapse('main-nav');
        hideCollapse('category-nav');
    });

    function hideCollapse(elementId) {
        $('#' + elementId + '').removeClass('show');
        $('.navbar-toggler[data-target="' + elementId + '"]').addClass('collapsed').attr('aria-expanded', 'false');
    }

    $(window).on('scroll resize', throttle(function () {
        condenseHeader();
        collapseSiteNav();
    }, throttleInterval));

    function condenseHeader() {
        var scrollDistance = 10;
        var currentDistance = $(window).scrollTop();
        var scrolledClass = 'scrolled';

        if (currentDistance < scrollDistance) {
            $body.removeClass(scrolledClass);
        } else {
            $body.addClass(scrolledClass);
        }
    }

    function collapseSiteNav() {
        $('#category-nav').removeClass('show');
        $('#main-nav').removeClass('show');
        $('#navbar-form').removeClass('show');
        $('.navbar-toggler').addClass('collapsed').attr('aria-expanded', 'false');
    }

    $('form.validate').each(function () {
        $(this).validate({
            errorElement: "span",
            errorClass: "help-inline",
            highlight: function (input) {
                var $input = $(input);
                var $inputGroup = $input.closest('.control-group').removeClass('valid');


                if ($input.val().length) {
                    $inputGroup.addClass('invalid');
                } else {
                    $inputGroup.removeClass('invalid');
                }
                $inputGroup.closest('form.validate').removeClass('form-valid').addClass('form-invalid');
            },
            success: function (input) {
                $(input).closest('.control-group').removeClass('invalid').addClass('valid');
                $(input).closest('form.validate').removeClass('form-invalid').addClass('form-valid');
            }
        });
    });

    var $searchForm = $('#search-form');

    if ($('.typeahead').length) {
        $('.typeahead').css('top', $('.js-site-header').height() + ' !important');
    }

    $('#site-search').typeahead({
        minLength: 3,
        source: function (query, process) {
            $searchForm.addClass('searching');
            $searchForm.removeClass('searching');
        }
    });

    $('#newsletter-form').submit(function (e) {
        e.preventDefault();
        var form = $(this);
        if (!form.validate()) {
            return false;
        }
        form.css('opacity', 0.5);
        $.getJSON(this.action + "?callback=&", $(this).serialize(), function (data) {
            var thanks = $('<p />', { text: "Thanks! We\'ll be in touch soon!" });
            if (data.Status === 400) {
                alert("Error: " + data.Message);
                form.css('opacity', 1);
                $('input:first', form).focus();
            } else {
                form.fadeOut(300, function () { $(this).css('opacity', 1).html(thanks).fadeIn(300); });
            }
        });
        return false;
    });

    if ($('.js-multi-item-carousel').length) {
        $('.next, .prev').show();

        $('.js-multi-item-carousel').each(function (key, item) {
            var sliderIdName = 'slider' + key;
            var numSlides = $(item).find('a').length;

            this.id = sliderIdName;

            var next = $(this).siblings('.top-section').find('.next');
            var prev = $(this).siblings('.top-section').find('.prev');

            var sliderId = '#' + sliderIdName;
            $(sliderId).not('.slick-initialized').slick({
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                prevArrow: prev,
                nextArrow: next,
                dots: true,
                infinite: true,
                responsive: [
                    {
                        breakpoint: 760,
                        settings: {
                            slidesToShow: numSlides >= 2 ? 2 : numSlides,
                            slidesToScroll: 1,
                            centerMode: true
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: numSlides >= 3 ? 3 : numSlides,
                            slidesToScroll: 1,
                            centerMode: false
                        }
                    }
                ]
            });

        });
    } else {
        $('.next, .prev').hide();
    }

    if ($('.js-slider-for').length) {
        $('.js-slider-for').not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.js-slider-nav'
        });
        $('.js-slider-nav').not('.slick-initialized').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.js-slider-for',
            dots: false,
            focusOnSelect: true
        });
    }
});

var throttle = function (fn, threshold, scope) {
    var last;
    var deferTimer;
    return function () {
        var context = scope || this;
        var now = Number(new Date());
        var args = arguments;
        if (last && now < last + threshold) {
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
            }, threshold + last - now);
        } else {
            last = now;
            fn.apply(context, args);
        }
    };
};

export function updateCartTotals() {
    $.uCommerce.getBasket({}, function (response) {
        console.log(JSON.stringify(response));
        var basket = response.Basket;
        var qtyUnit = parseInt(basket.FormattedTotalItems) == 1 ? itemTranslated : itemsTranslated;
        var basketIsEmpty = $('#empty-basket').length != 0;

        var $minibasket = basketIsEmpty ? $('#empty-basket') : $('#mini-basket');

        if (basketIsEmpty) {
            $minibasket.find('.js-minibasket-empty-text').remove();
            $minibasket.removeClass('is-empty').addClass('has-items');
            $minibasket.find('.js-minibasket-text').text(qtyUnit + " " + inBasketTranslated);
            $minibasket.attr('id', 'mini-basket');
            $minibasket.attr('href', cmsUrlPrefix + '/basket');
        }

        $minibasket.find('.js-minibasket-qty').text(basket.FormattedTotalItems);
        $minibasket.find('.js-minibasket-total').text(basket.FormattedOrderTotal);

        var $basket = $('#basket');

        $('.order-total', $basket).text(basket.FormattedOrderTotal);
        $('.order-tax', $basket).text(basket.FormattedTaxTotal);
    });
};
