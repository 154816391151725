import "jquery";
import "jquery-validation";
import "tether";
import "bootstrap";
import "slick-carousel";
import "./_uCommerce.bootstrap";
import "./_uCommerce.category";
import "./_uCommerce.demostore.cart";
import "./_uCommerce.demostore.checkout";
import "./_uCommerce.demostore";
import "./_uCommerce.demostore.productpage";
import "./_uCommerce.facets";
import "./_uCommerce.jQuery";
import "./_uCommerce.ServiceStack";
import "./custom/_navigation";
import "./custom/_videoArea";
import "./custom/_quoteSlider";
import "./custom/_acceptTerms";

